import { Configuration } from 'promsApi';

const timeoutFetch = (input, init) => {
    var timeoutPromise = new Promise(function (resolve, reject) {
        setTimeout(resolve, window._env_.REACT_APP_FETCH_TIMEOUT, {
            status: 'error',
            code: 666,
            data: 'TIMEOUT',
        });
    });

    return Promise.race([
        timeoutPromise,
        fetch(input, init)
    ]);
}

const getConfiguation = (dispatch, getState) => {
    var loginState = getState().login;
    var token = loginState.keycloak.token;
    var serverConfig = getState().serverConfig;

    var config = new Configuration(
        {
            "accessToken": "Bearer " + token,
            "basePath": serverConfig.serverUrl,
            "fetchApi": timeoutFetch
        }
    );
    return config;
}

export default getConfiguation;