import 'react-perfect-scrollbar/dist/css/styles.css';
import React, {useEffect, Suspense, } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import { GetRoutes } from 'src/routes';

import { connect } from 'react-redux';
import { LoginActions, ServerConfigActions, AuditActions } from 'src/comms';
import LoadingView from 'src/components/LoadingView';

import { useKeycloak } from "@react-keycloak/web";

import { HCPDataActions } from 'src/comms';
import { ConfigActions } from 'src/comms';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { GlobalDebug } from "src/utils/DisableLogging";


const App = (props) => {

  useEffect(() => {
    props.dispatch(ServerConfigActions.updateServerConfig(window._env_.REACT_APP_API_SERVER_URL));
  }, []);
  
  const navigate = useNavigate();
  const { keycloak, initialized } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;

    /**
   * @REMOVE_CONSOLES
   * // remove the working of console logs
   * // remove any accidental use of console logs
   */
    useEffect(() => {
      (process.env.NODE_ENV === "production" ||
       process.env.REACT_APP_ENV === "STAGING") &&
        GlobalDebug(false);
    }, []);

  const getConfig = (newLogin) => {
    console.log("PERMISSIONS");
    props.dispatch(HCPDataActions.getHcpProfile());
    props.dispatch(ConfigActions.fetchRolePermissionsConfig());
    props.dispatch(ConfigActions.getPromsEmailConfig());
    props.dispatch(ConfigActions.getPromsPrintConfig());
    props.dispatch(ConfigActions.getEnabledLanguages());
    props.dispatch(ConfigActions.fetchReportingUrls());

    if ( newLogin ){
      props.dispatch(AuditActions.userLoggedIn());
    }
  }

  useEffect(()=>{
    console.log("*** UseEffect");
    if ( isLoggedIn && keycloak.token){
      console.log("*** isLoggedIn && keycloak token");
      const payload = {
        keycloak: keycloak
      };

      keycloak.onAuthLogout = () => {
        console.log("*** onAuthLogout");
        props.dispatch(AuditActions.userTimeOutLogout());
        props.dispatch(LoginActions.timeOutLogout());
        navigate('/app/dashboard', { replace: true });
      }

      // Absence of permissions indicating new login.
      const newLogin = !hasPermissions();

      // Refresh permissions
      props.dispatch(LoginActions.getPermissions(payload, () => getConfig(newLogin)));
    }

  }, [isLoggedIn])


  const hasPermissions = () => {
    return props.permissions.length > 0;
  }
  
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Suspense fallback={<LoadingView isLoading={true} />}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {GetRoutes(isLoggedIn && hasPermissions(), props.permissions)}
        </MuiPickersUtilsProvider>
      </Suspense>
    </ThemeProvider>
  );
};

function mapStateToProps(state) {
  return {
    loginState: state.login,
    permissions: state.login.permissions
  };
}

const connectedApp = connect(mapStateToProps)(App);
export default connectedApp; 
