import i18n from 'src/i18n/config';


const onRequest = (promise, dispatch, successHandler, hasError, isLoading, successData, contextErrorMessage) => {
    promise.then((response) => {
      dispatch(successHandler(response, successData));
      dispatch(isLoading(false));
    }
    ).catch((e) => {
      // Report error
      console.log("Error");
      console.log(e);
      if (e.name === "ResponseError") {
        e.response.json().then((errorResponse) => {
          console.log(errorResponse);

          console.log(errorResponse.errorCode);
          const errorMessage = i18n.t(errorResponse.errorCode, { ns: 'errors' } );

          dispatch(isLoading(false));
          dispatch(hasError(true, errorMessage));
        }
        );
      } else if (e.name === "FetchError") { 
        const errorMessage = i18n.t("fetch_error", { ns: 'errors' });

        dispatch(isLoading(false));
        dispatch(hasError(true, errorMessage));
      } else {
        const errorMessage = i18n.t("system.internal_server_error", { ns: 'errors' });
        dispatch(isLoading(false));
        dispatch(hasError(true, errorMessage));
      }
    });
  }

  export default onRequest;