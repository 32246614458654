/* tslint:disable */
/* eslint-disable */
/**
 * Apperta Physical Health REST API
 * Apperta Physical Health REST API
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT34
 * Contact: info@staircase13.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Represents a patients proms email request
 * @export
 * @interface PromsEmailDto
 */
export interface PromsEmailDto {
    /**
     * Procedure id
     * @type {string}
     * @memberof PromsEmailDto
     */
    procedureId?: string;
    /**
     * Title of proms event
     * @type {string}
     * @memberof PromsEmailDto
     */
    title: string;
    /**
     * Procedure type
     * @type {string}
     * @memberof PromsEmailDto
     */
    procedureType: PromsEmailDtoProcedureTypeEnum;
    /**
     * Proms json
     * @type {string}
     * @memberof PromsEmailDto
     */
    promsJson: string;
    /**
     * Pathway id
     * @type {string}
     * @memberof PromsEmailDto
     */
    pathwayId: string;
    /**
     * Pathway uuid
     * @type {string}
     * @memberof PromsEmailDto
     */
    pathwayUid: string;
    /**
     * Pathway name
     * @type {string}
     * @memberof PromsEmailDto
     */
    pathwayName: string;
    /**
     * The scheduled date and time
     * @type {number}
     * @memberof PromsEmailDto
     */
    scheduledDateTime: number;
    /**
     * The step id
     * @type {string}
     * @memberof PromsEmailDto
     */
    stepId?: string;
    /**
     * Patients email address
     * @type {string}
     * @memberof PromsEmailDto
     */
    emailAddress: string;
    /**
     * The user for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof PromsEmailDto
     */
    userId: string;
    /**
     * The namespace for the userId for whom the composition should be stored, or empty if for the current user
     * @type {string}
     * @memberof PromsEmailDto
     */
    userIdNamespace: string;
}


/**
 * @export
 */
export const PromsEmailDtoProcedureTypeEnum = {
    None: 'None',
    Pre: 'Pre',
    Post: 'Post'
} as const;
export type PromsEmailDtoProcedureTypeEnum = typeof PromsEmailDtoProcedureTypeEnum[keyof typeof PromsEmailDtoProcedureTypeEnum];


/**
 * Check if a given object implements the PromsEmailDto interface.
 */
export function instanceOfPromsEmailDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "procedureType" in value;
    isInstance = isInstance && "promsJson" in value;
    isInstance = isInstance && "pathwayId" in value;
    isInstance = isInstance && "pathwayUid" in value;
    isInstance = isInstance && "pathwayName" in value;
    isInstance = isInstance && "scheduledDateTime" in value;
    isInstance = isInstance && "emailAddress" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "userIdNamespace" in value;

    return isInstance;
}

export function PromsEmailDtoFromJSON(json: any): PromsEmailDto {
    return PromsEmailDtoFromJSONTyped(json, false);
}

export function PromsEmailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromsEmailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'procedureId': !exists(json, 'procedureId') ? undefined : json['procedureId'],
        'title': json['title'],
        'procedureType': json['procedureType'],
        'promsJson': json['promsJson'],
        'pathwayId': json['pathwayId'],
        'pathwayUid': json['pathwayUid'],
        'pathwayName': json['pathwayName'],
        'scheduledDateTime': json['scheduledDateTime'],
        'stepId': !exists(json, 'stepId') ? undefined : json['stepId'],
        'emailAddress': json['emailAddress'],
        'userId': json['userId'],
        'userIdNamespace': json['userIdNamespace'],
    };
}

export function PromsEmailDtoToJSON(value?: PromsEmailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'procedureId': value.procedureId,
        'title': value.title,
        'procedureType': value.procedureType,
        'promsJson': value.promsJson,
        'pathwayId': value.pathwayId,
        'pathwayUid': value.pathwayUid,
        'pathwayName': value.pathwayName,
        'scheduledDateTime': value.scheduledDateTime,
        'stepId': value.stepId,
        'emailAddress': value.emailAddress,
        'userId': value.userId,
        'userIdNamespace': value.userIdNamespace,
    };
}

