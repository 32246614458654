import { v4 as uuid } from 'uuid';

import { FETCH_PATIENTS } from './Actions';
import { ADD_PATIENT } from './Actions';
import { UPDATE_PATIENT } from './Actions';
import { PATIENT_UPDATE_ERROR } from './Actions';
import { PATIENTS_LOADING } from './Actions';
import { PATIENTS_SUCCESS_CLEAR } from './Actions';
import { FETCH_PATIENT_DETAILS } from './Actions';
import { PATIENT_EMAIL_SENT_SUCCESS } from './Actions';
import { PATIENT_DETAILS_CLEAR } from './Actions'

import { PatientsEndpointApi } from 'promsApi';
import getConfiguation from './Configuration';
import onRequest from './Request';

const patientsError = (bool, errorMsg) => {
    return {
        type: PATIENT_UPDATE_ERROR,
        hasError: bool,
        errorMsg: errorMsg
    }
};

const patientsLoading = (bool) => {
    return {
        type: PATIENTS_LOADING,
        isLoading: bool
    }
};

const clearPatientSuccess = () => {
    return {
        type: PATIENTS_SUCCESS_CLEAR
    }
};

const clearPatientDetails = () => {
    return {
        type: PATIENT_DETAILS_CLEAR
    }
};

const clearPatientError = () => {
    return {
        type: PATIENT_UPDATE_ERROR,
        hasError: false,
        errorMsg: null
    }
};

const fetchPatientsSuccessHandler = (payload, searchTerm) => {
    return {
        type: FETCH_PATIENTS,
        patients: payload == null ? {} : payload.profiles,
        total: payload.total,
        pageStart: payload.start,
        searchTerm: searchTerm,
        orderTerm: payload.orderTerm,
        orderDirection: payload.orderDirection,
        fetchTime: new Date().getTime()
    }
};

const fetchPatientDetailsSuccessHandler = (payload) => {
    return {
        type: FETCH_PATIENT_DETAILS,
        patientDetails: payload
    }
};

const fetchPatientDetails = (userId, userIdNamespace, forceRefresh) => {
    console.log('Fetching patient profile');

    return (dispatch, getState) => {
        const patientDetails = getState().patients.patientDetails;
        if (patientDetails === null ||
            patientDetails.primaryId !== userId
            || patientDetails.primaryIdNamespace !== userIdNamespace
            || forceRefresh === true
            ) {
            console.log('Change of patient');
   
            if ( !forceRefresh ){
                dispatch(clearPatientDetails());
            }

            dispatch(patientsLoading(true));

            var config = getConfiguation(dispatch, getState);
            var patientApi = new PatientsEndpointApi(config);
            var request = patientApi.getProfile2({ "userId": userId, "userIdNamespace": userIdNamespace });
            onRequest(request, dispatch, fetchPatientDetailsSuccessHandler, patientsError, patientsLoading)
        }
    };
};


const fetchPatients = (searchTerm, searchTermMapping, pageSize, start, orderTerm, orderDirection) => {
    console.log('Fetching patient list');

    return (dispatch, getState) => {
        dispatch(patientsLoading(true));

        var config = getConfiguation(dispatch, getState);
        var patientApi = new PatientsEndpointApi(config);

        const patientSearchRequest = {
            "pageSize": pageSize,
            "start": start,
            "searchTerms": searchTermMapping,
            "orderTerm": orderTerm,
            "orderDirection": orderDirection
        };

        var request = patientApi.findPatients({ "searchRequest": patientSearchRequest })
        onRequest(request, dispatch, fetchPatientsSuccessHandler,patientsError, patientsLoading, searchTerm)
    };
};

const createPatientSuccessHandler = (response) => {
    response.procedures = []; // TODO fetch procedures etc

    return {
        type: ADD_PATIENT,
        patient: response
    }
};

const createPatient = (profile) => {
    console.log("Create Patient");

    return (dispatch, getState) => {
        dispatch(patientsLoading(true));

        var config = getConfiguation(dispatch, getState);
        var patientApi = new PatientsEndpointApi(config);
        var request = patientApi.create1({ "profileDto": profile })
        onRequest(request, dispatch, createPatientSuccessHandler, patientsError, patientsLoading)
    };
};

const updatePatientSuccess = (patient) => {
    return {
        type: UPDATE_PATIENT,
        patient: patient
    }
};

const updatePatient = (profile) => {
    console.log("Update Patient");
    console.log(profile);

    return (dispatch, getState) => {
        dispatch(patientsLoading(true));

        var config = getConfiguation(dispatch, getState);
        var patientApi = new PatientsEndpointApi(config);
        var request = patientApi.update({ "profileDto": profile });
        onRequest(request, dispatch, updatePatientSuccess, patientsError, patientsLoading)
    };
};

const sendPatientPromsEmailRequestSuccess = () => {
    return {
        type: PATIENT_EMAIL_SENT_SUCCESS
    }
};

const sendPatientPromsEmailRequest = (promsEmail) => {
    console.log("Sending patient proms email");

    return (dispatch, getState) => {
        dispatch(patientsLoading(true));
        dispatch(clearPatientError());
        dispatch(clearPatientSuccess());

        var config = getConfiguation(dispatch, getState);
        var patientApi = new PatientsEndpointApi(config);
        var request = patientApi.sendPromsEmail({ promsEmailDto: promsEmail })
        onRequest(request, dispatch, sendPatientPromsEmailRequestSuccess, patientsError, patientsLoading, null, "Error sending proms email request")
    };
};

export default {
    fetchPatients,
    updatePatient,
    fetchPatientDetails,
    createPatient,
    clearPatientSuccess,
    clearPatientDetails,
    clearPatientError,
    sendPatientPromsEmailRequest
}

